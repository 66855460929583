@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Michroma:wght@400;500;600;900&display=swap");

* {
  margin: 0;
  font-family: "Inter", sans-serif;
  --primary100: #F59025;
}

h1 {
  font-family: "Michroma", sans-serif !important;
}
